import { useEffect, useState } from 'react'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import { useGetUserBanReasonQuery } from '../../../reactQuery/hooks/customQueryHook'
import { useTranslation } from 'react-i18next'
import { useDeletePlayerBanReason, useUpdateUserBanStatus } from '../../../reactQuery/hooks/customMutationHook'
import { toast } from '../../../components/Toast'
import { useQueryClient } from '@tanstack/react-query'

const usePlayerStatus = () => {
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('reasonId')
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('ASC')
  const [enabled, setEnabled] = useState(false)   
  const [isActive, setIsActive] = useState('all') 
  const [isAccountClose, setIsAccountClose] = useState(true)
  const queryClient = useQueryClient(); 
  const { t } = useTranslation(['translation']);
  const isInitialRender = useDidMountEffect()

  const { data: reasonData, refetch: fetchData, isLoading: loading } = useGetUserBanReasonQuery({
    params: {
      limit,
      pageNo: page,
      orderBy,
      sort,
      search,
      isActive,
      isAccountClose: isAccountClose,
    },
    enabled
  })

  const { mutate: updateUserStatusMutation } = useUpdateUserBanStatus({
    onSuccess: (data) => {
      toast(data.data.message, 'success')
    }
  })

  const { mutate: deleteReasonMutation } = useDeletePlayerBanReason({
    onSuccess: ({ data }) => {
      if (data?.success) {
        toast(data.message, 'success');
        queryClient.invalidateQueries('reasonsList'); // Invalidate reasonsList query after delete
      }
    },
  });


  const totalPages = Math.ceil(reasonData?.data?.count / limit)

  useEffect(() => {
    setEnabled(true)
  }, [])

  const selected = (h) =>
    orderBy === h.value &&
    h.labelKey !== 'Role' &&
    h.labelKey !== 'Status' &&
    h.labelKey !== 'Action' &&
    h.labelKey !== 'Group'

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          fetchData()
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  // useEffect(() => {
  //   !isInitialRender && fetchData()
  // }, [page])

  useEffect(() => {
    setPage(1)
    !isInitialRender && fetchData()
    fetchData()
  }, [limit, orderBy, sort, page])


  useEffect(() => {
    setEnabled(true)
  }, [])

  return {
    t,
    reasonData,
    updateUserStatusMutation,
    isAccountClose,
    setIsAccountClose,
    deleteReasonMutation
  }
}

export default usePlayerStatus
