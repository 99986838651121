import React from 'react'

import Vault from '../PlayerDetails/components/Vault'

const VaultData = () => {
    return (
      
        <Vault isAllUser={true}/>
    )
}

export default VaultData