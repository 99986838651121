export const tableHeaders = [
  { labelKey: 'headers.id', value: 'masterCasinoGameId' },
  { labelKey: 'headers.name', value: 'name' },
  { labelKey: 'headers.thumbnail', value: '' },
  { labelKey: 'headers.status', value: 'isActive' },
  { labelKey: 'headers.provider', value: 'masterCasinoProviderId' },
  { labelKey: 'headers.createdAt', value: 'createdAt' },
  { labelKey: 'headers.rtp', value: 'returnToPlayer' },
  { labelKey: 'headers.action', value: '' }
]

export const allowedKeysForOrder = [
  'masterCasinoGameId',
  'name'
]
