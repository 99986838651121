import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Table, Spinner } from "@themesberg/react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getPackageHistory } from "../../../utils/apiCalls";
import { Link } from 'react-router-dom'


const ViewPackages = () => {
    const [limit, setLimit] = useState(15);
    const [pageNo, setPageNo] = useState(1);
    const [orderBy, setOrderBy] = useState('');

    const { packageId } = useParams();

    const { data: packageData = [], isLoading: loading, error } = useQuery({
        queryKey: ['packageData', limit, pageNo, orderBy, packageId],
        queryFn: async ({ queryKey }) => {
            const params = {
                pageNo: queryKey[2],
                limit: queryKey[1],
                packageId: queryKey[4],
                ...(queryKey[3] && { orderBy: queryKey[3] })
            };
            return getPackageHistory(params);
        },
        select: res => res?.data?.userDetails || [],
        refetchOnWindowFocus: false,
    });


    return (
        <div>
            <Row>
                <Col sm={8}>
                    <h3>View Packages</h3>
                </Col>
            </Row>

            <Row className="mt-3">
                <div style={{ overflow: 'auto' }}>
                    <Table bordered striped hover size='sm' className='text-center mt-4'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Email</th>
                                <th>Number of times purchased</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )
                            }
                            {packageData?.map((user, idx) => (
                                <tr key={idx}>
                                    <td><Link to={`/admin/player-details/${user.actioneeId}`}>{user.transactionUser.email}</Link></td>
                                    <td>{user.claimedCount}</td>
                                    <td>{user.claimedAmount}</td>
                                </tr>
                            ))}
                            {(packageData?.length === 0) && (
                                <tr>
                                    <td className='text-danger' colSpan={3}>No Data Available</td>
                                </tr>
                            )}

                        </tbody>
                    </Table>
                </div>
            </Row>
        </div>
    );
};

export default ViewPackages;
