import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { Row, Col, Form as BForm, Button } from '@themesberg/react-bootstrap'
import { PlayerSearchContainer } from './style'
import { playerSearchSchmes } from './schemas'
import { checkForReset, initialSet } from './constants'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import Trigger from '../../components/OverlayTrigger'
import { onDownloadCsvClick } from '../../utils/helper'
import useTierListing from '../Tier/hooks/useTierListing'

const PlayerSearch = (props) => {
  const {
    globalSearch,
    setGlobalSearch,
    getCsvDownloadUrl,
    playersData
  } = props

  const { tierList } = useTierListing()
  const tierOptions = tierList?.rows

  const resetToggler = (resetForm) => {
    resetForm()
    setGlobalSearch(initialSet)
  }
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleDownloadClick = async (values) => {
    try {
      const { idSearch, emailSearch, firstNameSearch, lastNameSearch, userNameSearch, phoneSearch, tierSearch } = values;
      const baseFilename = 'Player_Details';
      const parts = [idSearch, emailSearch, firstNameSearch, lastNameSearch, userNameSearch, phoneSearch, tierSearch]
        .filter(value => value !== '');
      
      const filename = parts.length > 0 
        ? `${baseFilename}_${parts.join('_')}`
        : baseFilename;
        
      setDownloadInProgress(true);
      const url = getCsvDownloadUrl();
      await onDownloadCsvClick(url, filename)
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      setDownloadInProgress(false);
    }
  };

  return (
    <PlayerSearchContainer>
      <Formik
        initialValues={{
          idSearch: '',
          emailSearch: '',
          firstNameSearch: '',
          lastNameSearch: '',
          userNameSearch: '',
          affiliateIdSearch: '',
          phoneSearch: '',
          tierSearch: ''
        }}
        validationSchema={playerSearchSchmes()}
        onSubmit={(formValues, { resetForm }) => {
          console.log(formValues)
          const tempValue = { ...formValues }
          setGlobalSearch(tempValue)
        }}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          resetForm,
          setFieldValue
        }) => (
          <Form>
            <Row>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='idSearch'>
                  <BForm.Label>Player Id</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='idSearch'
                    placeholder='Player ID'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.idSearch}
                  />
                  <ErrorMessage
                    component='div'
                    name='idSearch'
                    className='text-danger'
                  />
                </BForm.Group>
              </Col>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='formGroupEmail'>
                  <BForm.Label>Email</BForm.Label>
                  <BForm.Control
                    type='text'
                    placeholder='Email'
                    name='emailSearch'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailSearch}
                  />
                  <ErrorMessage
                    component='div'
                    name='emailSearch'
                    className='text-danger'
                  />
                </BForm.Group>
              </Col>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='formGroupEmail'>
                  <BForm.Label>First Name</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='firstNameSearch'
                    placeholder='First Name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstNameSearch}
                  />
                </BForm.Group>
              </Col>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='formGroupEmail'>
                  <BForm.Label>Last Name</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='lastNameSearch'
                    placeholder='Last Name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastNameSearch}
                  />
                </BForm.Group>
              </Col>
            </Row>
            <Row>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='formGroupEmail'>
                  <BForm.Label>User Name</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='userNameSearch'
                    placeholder='UserName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userNameSearch}
                  />
                </BForm.Group>
              </Col>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='formGroupEmail'>
                  <BForm.Label>Mobile Number</BForm.Label>
                  <BForm.Control
                    type='text'
                    name='phoneSearch'
                    placeholder='Mobile number'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneSearch}
                  />
                </BForm.Group>
              </Col>
              <Col className='col-lg-3 col-sm-6 col-12'>
                <BForm.Group className='mb-3' controlId='formGroupTier'>
                  <BForm.Label>Tier</BForm.Label>
                  <BForm.Control
                    as='select'
                    name='tierSearch'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tierSearch}
                  >
                    <option disabled id={'none'} value=''>-- Select Tier --</option>
                    {tierOptions?.map((tier) => {
                      return (
                        <option key={tier?.tierId} id={tier?.tierId} value={tier?.level}>{tier?.name}</option>
                      )
                    })}
                  </BForm.Control>

                </BForm.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='buttonDiv'>
                  <Button variant='primary' type='submit'>Search</Button>
                  <div>
                    <Button variant='secondary' onClick={() => resetToggler(resetForm)} type='button'>Reset</Button>

                    <Trigger message='Download as CSV' id={'csv'} />
                    <Button
                      id={'csv'}
                      variant='success' style={{ marginLeft: '10px' }}
                      onClick={() => handleDownloadClick(values)}
                      disabled={downloadInProgress}
                    >
                      {downloadInProgress ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        <FontAwesomeIcon icon={faFileDownload} />
                      )}
                    </Button>
                  </div>

                </div>
              </Col>
            </Row>
          </Form>)}
      </Formik>
    </PlayerSearchContainer>
  )
}

export default PlayerSearch;