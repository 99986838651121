import React from 'react'
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useState } from "react";
import { Bar } from "react-chartjs-2";

Chart.register(CategoryScale);

export default function BarChart({ graphTitle, graphData }) {
  const [chartData, setChartData] = useState({
    labels: graphData?.graphData.map((data) => data.date),
    datasets: [
      {
        label: "Approved Amount",
        backgroundColor: "#2DCE89",
        borderColor: "#2DCE89",
        borderWidth: 2,
        fontColor: "white",
        data: graphData?.graphData.map((data) => data.approvedAmount)
      },
      {
        label: "Pending Amount",
        backgroundColor: "#11CDEF",
        borderColor: "#11CDEF",
        borderWidth: 2,
        fontColor: "#11CDEF",
        data: graphData?.graphData.map((data) => data.pendingAmount)
      },      
    ]
  });


  // const optionsChart = {
  //   legend: {
  //     display: true,
  //     position: "top",
  //     labels: {
  //       fontColor: "#8898AA"
  //     }
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         gridLines: {
  //           color: "#DEE2E6",
  //           zeroLineColor: "#DEE2E6"
  //         },
  //         ticks: {
  //           fontColor: "black",
  //           callback: function (value) {
  //             if (!(value % 2)) {
  //               //return '$' + value + 'k'
  //               return value;
  //             }
  //           }
  //         },
  //         stacked: true
  //       }
  //     ],
  //     xAxes: [
  //       {
  //         ticks: {
  //           fontColor: "black"
  //         },
  //         stacked: true
  //       }
  //     ]
  //   },
  //   // tooltips: { // Khi rê chuột hiển thị từng data
  //   //   callbacks: {
  //   //     label: function(item, data) {
  //   //       var label = data.datasets[item.datasetIndex].label || "";
  //   //       var yLabel = item.yLabel;
  //   //       var content = "";
  //   //       if (data.datasets.length > 1) {
  //   //         content += label;
  //   //       }
  //   //       content += yLabel;
  //   //       return content;
  //   //     }
  //   //   }
  //   // }

  //   tooltips: {
  //     enabled: true,
  //     mode: "index",
  //     intersect: true
  //   }
  // };



  return (
    <div className="App">
      <div className="chart-container">
        <h5 style={{ textAlign: "center" }}>{graphTitle}</h5>
        <Bar data={chartData} />
      </div>
    </div>
  );

}