import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRaffle } from '../../../utils/apiCalls'
import { toast } from '../../../components/Toast'
import { errorHandler, useDeleteTournament, useUpdateStatusMutation, useUpdateStatusRaffleMutation, useUpdateStatusTournamentMutation, useUpdateTournamentCronMutation } from '../../../reactQuery/hooks/customMutationHook'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { formatDateYMD, getDateDaysAgo } from '../../../utils/dateFormatter'

const useRaffleListing = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { t } = useTranslation('casino');
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('tournamentId')
  const [sort, setSort] = useState('DESC')
  const [over, setOver] = useState(false)
  const [raffleId, setRaffleId] = useState()
  const [active, setActive] = useState()
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [type, setType] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [tournamentId, setTournamentId] = useState('')
  const [statusFilter, setStatusFilter] = useState('all')
  const [search, setSearch] = useState('')
  const [dataLoading, setDataLoading] = useState(false)
  const [debouncedSearch] = useDebounce(search, 500)
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const { data: raffleList, isLoading: loading } = useQuery({
    queryKey: ['raffleList', limit, page, orderBy, 
    sort],
    queryFn: ({ queryKey }) => {
      const params = {pageNo: queryKey[2], limit: queryKey[1]};
      // if (queryKey[4]) params.orderBy = queryKey[3]
      if (queryKey[5]) params.sort = queryKey[4]
      return getRaffle(params)
    },
    select: (res) => res?.data?.raffleDetails,
    refetchOnWindowFocus: false
  })

  const selected = (h) =>
    orderBy === h.value &&
    h.labelKey !== 'Actions'

  const totalPages = Math.ceil(raffleList?.count / limit)

  const handleShow = (id, active) => {
    setRaffleId(id)
    setActive(!active)
    setShow(true)
  }
  const { mutate: updateStatus } = useUpdateStatusRaffleMutation({
    onSuccess: ({ data }) => {
      // if (data.success) {
        if (data.message) toast(data.message, 'success')
        queryClient.invalidateQueries({ queryKey: ['raffleList'] })
      // }
      setShow(false)
      setDataLoading(false)
    }, onError: (error) => {
      errorHandler(error)
    }
  })

  const handleYes = () => {
    setDataLoading(true)
    updateStatus({
      raffleId: raffleId,
      isActive: active
    })
  }

  const handleClose = () => setShowModal(false)

  // const handleShowModal = (type) => {
  //   setType(type)
  //   setShowModal(true)
  // }

  const { mutate: deleteCategory } = useDeleteTournament({
    onSuccess: ({ data }) => {
      if (data?.success) {

        if (data?.message) toast(data.message, 'success')
        queryClient.invalidateQueries({ queryKey: ['raffleList'] })
      }
      setDeleteModalShow(false)
    }
  })

  const handleDeleteYes = () => {
    deleteCategory({ tournamentId })
  }

  const handleDeleteModal = (id) => {
    setTournamentId(id)
    setDeleteModalShow(true)
  }

  return {
    t,
    limit,
    page,
    loading,
    raffleList,
    show,
    setLimit,
    setPage,
    setShow,
    totalPages,
    handleShow,
    handleYes,
    showModal,
    type,
    handleClose,
    selectedCategory,
    setSelectedCategory,
    active,
    navigate,
    handleDeleteModal,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    setOrderBy,
    selected,
    sort,
    setSort,
    over,
    setOver,
    statusFilter,
    setStatusFilter,
    search,
    setSearch,
    selectedCurrency,
    setSelectedCurrency,
    state,
    setState,
    setType,dataLoading
  }
}

export default useRaffleListing
