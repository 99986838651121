import React from 'react'
import { casinoCategorySchema } from '../schemas'
import { Formik, Form, ErrorMessage, FieldArray } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
  Modal,
  ProgressBar,
  Tabs,
  Tab
} from '@themesberg/react-bootstrap'
import useCreateTournaments from '../hooks/useCreateTournaments'
import { formatDateMDY, formatDateYMD, getDateTime, getDateTimeByYMD } from '../../../utils/dateFormatter'
import Datetime from 'react-datetime';
import Trigger from '../../../components/OverlayTrigger';
import { serialize } from 'object-to-formdata';
import ReactCreatable from '../../../components/ReactSelectField/ReactCreatable'
import { initialWinnerPercentage } from '../constants'
import { toast } from '../../../components/Toast'
import LeaderBoard from './LeaderBoard'

const CreateTournament = ({
  data, details
}) => {
  const {
    t,
    loading,
    updateTournament,
    createTournamentList,
    handleNumberOfWinners,
    setGamesIdsOptions,
    gameIdsOptions,
    isSelectLoading,
    setWinners,
    Winners,
    numberOfWinnersValue
  } = useCreateTournaments()
  const tournamentData = data?.tournament || null
  const tournamentLeaderBoard = data?.tournamentLeaderBoard || []
  const yesterday = new Date(Date.now() - 86400000);
  function WithLabelExample(info) {
    let sum = info?.reduce((acc, o) => acc + parseInt(o), 0)
    // if(info.includes('')){
    //   let copyData=[...info]
    //  let value= copyData.splice(info.indexOf(''), 1)
    //  sum=copyData?.reduce((acc, o) => acc + parseInt(o), 0)
    // }
    return <ProgressBar now={sum} label={`${sum}%`} />;
  }
  const filterData = (numbers) => {
    const filteredNumbers = [];

    for (let i = 0; i < numbers; i++) {
      const element =
        <>
          <Col md={6} sm={12} className='mt-3'>
            <BForm.Label>
              {t('tournaments.inputField.numberOfWinners.label')}-{i + 1}
              <span className='text-danger'> *</span>
            </BForm.Label>

            <BForm.Control
              type='number'
              id={i}
              name={`numberOfWinners-${i}`}
              min='0'
              placeholder={`Enter percentage of winner-${i + 1}`}
              value={Winners[i]}
              onChange={(e) => {
                setWinners({ ...Winners, [e.target.id]: e.target.value }
                )

              }}
            // initialWinnerPercentage[numbers][i]
            />

            <ErrorMessage
              component='div'
              name='numberOfWinners'
              className='text-danger'
            />
          </Col>
        </>
      filteredNumbers.push(element);
    }
    return filteredNumbers || []

  };
  return (
    <>
      <Row>
        <Col sm={12}>
          <h3>
            {tournamentData ? details ? "View" : "Edit" : 'Create'} {t('tournaments.createCategory.label')}
          </h3>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey={tournamentData ? details ? "View" : "Edit" : 'Create'}
        id="justify-tab-example"
        className={`${tournamentData && details ? 'mt-5 ms-2' : 'mt-2'} m-3`}
      // justify
      >
        <Tab eventKey={tournamentData ? details ? "View" : "Edit" : 'Create'} title={tournamentData ? details ? "View" : null : null}>
          <Formik
            enableReinitialize
            initialValues={{
              isActive: tournamentData ? tournamentData.isActive : false,
              title: tournamentData ? tournamentData.title : '',
              entryAmount: tournamentData ? tournamentData.entryAmount : '',
              endDate: tournamentData ? tournamentData.endDate ? getDateTime(tournamentData.endDate) : '' : new Date(),
              startDate: tournamentData ? tournamentData.startDate ? getDateTime(tournamentData.startDate) : '' : new Date(),
              playerLimit: tournamentData ? tournamentData.playerLimit : '',
              winSc: tournamentData ? tournamentData.winSc : '',
              winGc: tournamentData ? tournamentData.winGc : '',
              gameId: tournamentData ? tournamentData?.games.length > 0 && tournamentData?.games?.map((item) => {
                return {
                  label: `${item.name} (RTP:${item?.returnToPlayer ? item?.returnToPlayer : ''})`,
                  value: item.masterCasinoGameId
                }
              }) : [],
              description: tournamentData ? tournamentData.description : '',
              entryCoin: tournamentData ? tournamentData.entryCoin : 'SC',
              winnerPercentage: tournamentData ? tournamentData.winnerPercentage : [],
              numberOfWinners: tournamentData ? tournamentData?.winnerPercentage?.length : '',
              playerLimitIsActive: tournamentData ? tournamentData?.playerLimit ? true : false : false,


              // thumbnail: null,
            }}
            validationSchema={casinoCategorySchema(t)}
            onSubmit={(formValues) => {
              const sum = formValues?.winnerPercentage.length > 0 && formValues?.winnerPercentage?.reduce((acc, o) => acc + parseInt(o), 0)
              if (sum !== 100) {
                toast(('Sum of all winner player percentage should be 100'), 'error')
                return null
              }
              // let data = { ...formValues, gameId: formValues?.gameId?.map((info) => info.value) || [] }
              let data = {
                isActive: formValues.isActive,
                title: formValues.title,
                entryAmount: Number(formValues.entryAmount),
                endDate: formValues.endDate,
                startDate: formValues.startDate,
                playerLimit: formValues?.playerLimitIsActive ? formValues?.playerLimit ? Number(formValues.playerLimit) : null : null,
                winSc: Number(formValues.winSc),
                winGc: Number(formValues.winGc),
                gameId: formValues?.gameId?.map((info) => Number(info.value)) || [],
                description: formValues.description,
                entryCoin: formValues.entryCoin,
                winnerPercentage: formValues.winnerPercentage,
              }
              tournamentData
                ? updateTournament({
                  ...data,
                  tournamentId: tournamentData?.tournamentId,
                })
                : createTournamentList(data)



            }}
          >
            {({ values, handleChange, handleSubmit, errors, handleBlur, setFieldValue }) => (
              <Form>
                {/* <Modal.Body> */}
                <Row className='mt-3'>
                  <Col md={12} sm={12} className='mt-3'>
                    <BForm.Label>
                      {t('tournaments.inputField.categoryName.label')}<span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Control
                      type='text'
                      name='title'
                      placeholder={t('tournaments.inputField.categoryName.placeholder')}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={details}
                      autoComplete="off"
                    />

                    <ErrorMessage
                      component='div'
                      name='title'
                      className='text-danger'
                    />
                  </Col>

                  <Col md={12} sm={12} className='mt-3'>

                    <BForm.Label>
                      {t('tournaments.inputField.description.label')}
                      <span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Control
                      type='text'
                      as='textarea'
                      rows='3'
                      name='description'
                      // disabled={details}
                      placeholder={t('tournaments.inputField.description.placeholder')}
                      value={values?.description ? values?.description : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={details}
                    />

                    <ErrorMessage
                      component='div'
                      name='description'
                      className='text-danger'
                    />
                  </Col>


                  <Col md={6} sm={12} className='mt-3'>
                    <BForm.Label>
                      {t('tournaments.inputField.entryAmount.label')}
                      <span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Control
                      type='number'
                      name='entryAmount'
                      min='0'
                      placeholder={t(
                        'tournaments.inputField.entryAmount.placeholder'
                      )}
                      value={values.entryAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={details}
                    // disabled={isEdit}
                    />

                    <ErrorMessage
                      component='div'
                      name='entryAmount'
                      className='text-danger'
                    />
                  </Col>

                  <Col md={6} sm={12} className='mt-3'>

                    <BForm.Label style={{ minWidth: '108px' }}>
                      Joining Coin Type
                      <span className='text-danger'> *</span>
                    </BForm.Label>
                    <BForm.Select
                      type='text'
                      name={'entryCoin'}
                      value={values.entryCoin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={details}
                    >

                      {
                        <>
                          <option key={'SC'} value={'SC'}>
                            SC
                          </option>
                          <option key={'GC'} value={'GC'}>
                            GC
                          </option>
                        </>

                      }
                    </BForm.Select>
                  </Col>

                  <Col md={6} sm={12} className='mt-3'>
                    <BForm.Label>
                      {t('tournaments.inputField.startDate')}{' '}
                      <span className='text-danger'>*</span>
                    </BForm.Label>
                    <Datetime
                      inputProps={{
                        placeholder: 'MM-DD-YYYY HH:MM',
                        disabled: details
                      }}
                      dateFormat='MM/DD/YYYY'
                      onChange={(e) => {
                        setFieldValue('startDate', e);
                      }}
                      value={values.startDate}
                      isValidDate={(e) => {
                        return (
                          e._d > yesterday ||
                          getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                        );
                      }}
                      timeFormat={true}
                    />
                    <ErrorMessage
                      component='div'
                      name='startDate'
                      className='text-danger'
                    />
                  </Col>

                  <Col md={6} sm={12} className='mt-3'>
                    <BForm.Label>
                      {t('tournaments.inputField.endDate')}{' '}
                      <span className='text-danger'>*</span>
                    </BForm.Label>
                    <Datetime
                      inputProps={{
                        placeholder: 'MM-DD-YYYY HH:MM',
                        disabled: details
                      }}
                      dateFormat='MM/DD/YYYY'
                      onChange={(e) => {
                        setFieldValue('endDate', e);
                      }}
                      value={values.endDate}
                      isValidDate={(e) => {
                        return (
                          e._d >yesterday ||
                          getDateTimeByYMD(e._d) === getDateTimeByYMD(new Date())
                        );
                      }}
                      timeFormat={true}
                    />
                    <ErrorMessage
                      component='div'
                      name='endDate'
                      className='text-danger'
                    />
                  </Col>

                  <Col md={6} sm={12} className='mt-3'>
                    <BForm.Label>
                      {t('tournaments.inputField.winSc.label')}
                      <span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Control
                      type='number'
                      name='winSc'
                      min='0'
                      placeholder={t('tournaments.inputField.winSc.placeholder')}
                      value={values.winSc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={details} />

                    <ErrorMessage
                      component='div'
                      name='winSc'
                      className='text-danger'
                    />
                  </Col>

                  <Col md={6} sm={12} className='mt-3'>
                    <BForm.Label>
                      {t('tournaments.inputField.winGc.label')}
                      <span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Control
                      type='number'
                      name='winGc'
                      min='1'
                      placeholder={t('tournaments.inputField.winGc.placeholder')}
                      value={values.winGc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={details} />

                    <ErrorMessage
                      component='div'
                      name='winGc'
                      className='text-danger'
                    />
                  </Col>


                  <Col md={3} sm={6} className='d-flex mt-3'>
                    <BForm.Label>
                      {'Enable Player Limit'}
                      {/* <span className='text-danger'> *</span> */}
                    </BForm.Label>

                    <BForm.Check
                      type='switch'
                      name='playerLimitIsActive'
                      className='ms-6'
                      // placeholder='Enter Ac'
                      checked={values.playerLimitIsActive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={details} />

                    <ErrorMessage
                      component='div'
                      name='playerLimitIsActive'
                      className='text-danger'
                    />
                  </Col>
                  <Col md={3} sm={6} className='d-flex mt-3'>
                    <BForm.Label>
                      {t('tournaments.inputField.isActive.label')}
                    </BForm.Label>

                    <BForm.Check
                      type='checkbox'
                      className='ms-6'
                      name='isActive'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.isActive}
                      disabled={details} />
                  </Col>
                  {values.playerLimitIsActive && <Col md={6} sm={12} className='mt-3'>
                    <BForm.Label>
                      {t('tournaments.inputField.playerLimit.label')}
                      {values.playerLimitIsActive && <span className='text-danger'> *</span>}
                    </BForm.Label>

                    <BForm.Control
                      type='number'
                      name='playerLimit'
                      min='1'
                      onKeyDown={(e) => {
                        if (["e", ".", "-"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      placeholder={t(
                        'tournaments.inputField.playerLimit.placeholder'
                      )}
                      value={values.playerLimit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={details}
                    />

                    <ErrorMessage
                      component='div'
                      name='playerLimit'
                      className='text-danger'
                    />
                  </Col>}



                  <Col md={12} sm={12} className='mt-3'>
                    <BForm.Label>
                      {t('Games')}{' '}
                      <span className='text-danger'>*</span>
                    </BForm.Label>
                    <ReactCreatable
                      options={gameIdsOptions}
                      value={values.gameId}
                      setValue={(dataValue) => {
                        setFieldValue('gameId', dataValue || []);
                        // setGameIdValue(dataValue);
                      }}
                      isMulti={true}
                      isLoading={isSelectLoading || details}
                      // handleCreateOption={(optionValue) => {
                      //   setFieldValue('gameId', optionValue);
                      //   handleCreateOption(optionValue);
                      // }}
                    />
                    <ErrorMessage
                      component='div'
                      name='gameId'
                      className='text-danger'
                    />
                  </Col>




                  <Col md={6} sm={12} className='mt-3'>
                    <BForm.Label>
                      {t('tournaments.inputField.numberOfWinners.label')}
                      <span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Control
                      type='number'
                      name='numberOfWinners'
                      min='1'
                      placeholder={t(
                        'tournaments.inputField.numberOfWinners.placeholder'
                      )}
                      value={values.numberOfWinners}
                      onKeyDown={(e) => {
                        if (["e", ".", "-"].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue('numberOfWinners', e.target.value);
                        // handleNumberOfWinners(e)
                        if (e.target.value > 0 && e.target.value <= 5) {
                          const data = [...initialWinnerPercentage[e.target.value]]
                          setFieldValue('winnerPercentage', data)
                        }

                      }}
                      onBlur={handleBlur}
                      disabled={details} />

                    <ErrorMessage
                      component='div'
                      name='numberOfWinners'
                      className='text-danger'
                    />
                  </Col>
                  <Row className='mt-3'>
                    <Col md={6} sm={12}>
                      {values.winnerPercentage.length > 0 && WithLabelExample(values.winnerPercentage)}
                    </Col>
                  </Row>

                  <Row className='mt-3'>
                    <FieldArray
                      name="winnerPercentage"
                      render={({ push }) => {
                        return (
                          <div>
                            {values.winnerPercentage.length > 0 &&
                              values.winnerPercentage.map((info, i) => (
                                <Col md={4} sm={12} className='mt-3' key={`winnerPercentage-${i}-key`}>
                                  <BForm.Label>
                                    {'Rank'} {i + 1}
                                    <span className='text-danger'> *</span>
                                  </BForm.Label>

                                  <BForm.Control
                                    type='number'
                                    id={`winnerPercentage-${i}`}
                                    min='1'
                                    max='100'
                                    name={`winnerPercentage.${i}`}
                                    onKeyDown={(e) => {
                                      if (["e", ".", "-"].includes(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder={`Enter % of Rank  ${i + 1}`}
                                    value={info}
                                    disabled={details}
                                    onChange={(e) => {
                                      const newArray = [...values.winnerPercentage];
                                      let nameValue = e.target?.id?.split('-')[1]

                                      newArray[nameValue] = Number(e.target.value);
                                      let sumOfValues = newArray?.reduce((acc, o) => acc + parseInt(o), 0)
                                      if (sumOfValues > 100) {
                                        setFieldValue('winnerPercentage', [...values.winnerPercentage])
                                      }
                                      else
                                        setFieldValue('winnerPercentage', newArray)
                                    }}

                                  />

                                  {errors.winnerPercentage && errors.winnerPercentage[i] && errors.winnerPercentage[i].length >1 && (<ErrorMessage
                                    component='div'
                                    name={`winnerPercentage.${i}`}
                                    className='text-danger'
                                  />)}
                                </Col>
                              ))}
                          </div>
                        )
                      }}
                    />
                  </Row>

                </Row>
                {!Array.isArray(errors.winnerPercentage) && !errors.numberOfWinners &&
                  errors.winnerPercentage && <ErrorMessage
                    component='div'
                    name={`winnerPercentage`}
                    className='text-danger'
                  />
                }

                <div className='mt-4'>
                  <Button
                    hidden={details}
                    variant='success'
                    onClick={handleSubmit}
                    className='ml-2'
                    disabled={loading}
                  >
                    {t('tournaments.createCategory.submit')}
                    {loading && (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Tab>
        {tournamentData && details &&
          <Tab eventKey="LeaderBoard" title="LeaderBoard">
            <LeaderBoard list={tournamentLeaderBoard} />
          </Tab>}
      </Tabs>


    </>
  )
}

export default CreateTournament
