import { useEffect, useState } from 'react'
import { formatDateYMD, getDateDaysAgo } from '../../../utils/dateFormatter'
import { getItem, getLoginToken } from '../../../utils/storageUtils'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { getBankingTransactions, getVaultData } from '../../../utils/apiCalls'
import { useDebounce } from 'use-debounce'
import useTimeConversion from '../../../utils/TimeConversion'
import { timeZones } from '../../Dashboard/constants'
import { convertTimeZone, convertToUtc, getFormattedTimeZoneOffset } from '../../../utils/helper'

const useVaultList = (email) => {
 
  const { t } = useTranslation('players')
  const timezone = getItem("timezone")
  const [csvDownload, setCsvDownload] = useState(false)
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState(email || '')
  const [username, setUsername] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  const[debouncedUsername]=useDebounce(username,500)

  
  const { data: vaultData, isLoading: loading,refetch:vaultRefetch } = useQuery({
    queryKey: ['VaultList', limit, page,debouncedSearch,debouncedUsername],
    queryFn: ({ queryKey }) => {
      const params = { pageNo: queryKey[2], limit: queryKey[1] };
      if (queryKey[3]) params.email = queryKey[3]
      if (queryKey[4]) params.username = queryKey[4]
      if (queryKey[5]) params.order = queryKey[5]
      if (queryKey[5]) params.sortBy = queryKey[5]
      
      return getVaultData(params)
    },
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.vaultDetails
  })
  
  const totalPages = Math.ceil(vaultData?.count / limit)

  const getCsvDownloadUrl = () =>
   `${process.env.REACT_APP_API_URL}/api/v1/payment/vault-data?csvDownload=true&limit=${limit}&pageNo=${page}&email=${search}&username=${username}`

  return {
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    t,
    vaultData,
    loading,
    
    getCsvDownloadUrl,
    search,
    setSearch,
    vaultRefetch,
    setCsvDownload,username,setUsername
  }
}

export default useVaultList