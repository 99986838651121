import React, { useEffect, useState } from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  Form
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PaginationComponent from '../../components/Pagination'
import { ConfirmationModal, DeleteConfirmationModal } from '../../components/ConfirmationModal'
import {
  faCheckSquare,
  faEdit,
  faTrash,
  faEye,
  faArrowCircleUp,
  faArrowCircleDown,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons'
import Trigger from '../../components/OverlayTrigger'
import Preloader, { InlineLoader } from '../../components/Preloader'
import useCheckPermission from '../../utils/checkPermission'
import { AdminRoutes } from '../../routes'
import { tableHeaders } from './constants'
import useTournamentListing from './hooks/useTournamentListing'
import CreateTournament from './components/CreateTournament'
import { coinTypeOptions } from '../PlayerDetails/constants'
import DateRangePicker from '../../components/DateRangePicker'
import { getDateThreeMonthsBefore, getDateTime } from '../../utils/dateFormatter'
import { getItem } from '../../utils/storageUtils'
import { convertTimeZone, convertToTimeZone, getFormattedTimeZoneOffset } from '../../utils/helper'
import { timeZones } from '../Dashboard/constants'
import Datetime from 'react-datetime'

const Tournaments = () => {
  const {
    t,
    limit,
    page,
    loading,
    tournamentList,
    show,
    setLimit,
    setPage,
    setShow,
    totalPages,
    handleShow,
    handleYes,
    handleShowModal,
    showModal,
    type,
    handleClose,
    selectedCategory,
    setSelectedCategory,
    active,
    navigate,
    handleDeleteModal,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    setOrderBy,
    selected,
    sort,
    setSort,
    over,
    setOver,
    statusFilter,
    setStatusFilter,
    search,
    setSearch,
    selectedCurrency,
    setSelectedCurrency,
    transactionRefetch,
    state,
    setState, startDate, setStartDate, endDate, setEndDate, timezoneOffset, updateloading
  } = useTournamentListing()
  const { isHidden } = useCheckPermission()


  return (
    <>
      <>
        <Row className='mb-2'>
          <Col>
            <h3>{t('tournaments.title')}</h3>
          </Col>

          <Col>
            <div className='d-flex justify-content-end'>
              <Button
                variant='success'
                size='sm'
                style={{ marginRight: '10px' }}
                hidden={isHidden({ module: { key: 'CasinoManagement', value: 'C' } })}
                onClick={() => navigate(AdminRoutes.tournamentCreate)}
              >
                {t('tournaments.createButton')}
              </Button>

              {/* <Button
                variant='success'
                size='sm'
                hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                onClick={() => navigate(AdminRoutes.ReordertournamentList)}
              >
                {t('tournaments.reorder')}
              </Button> */}
            </div>
          </Col>
        </Row>

        <Row className='mb-3 w-100 m-auto'>
          {/* <Col xs='12' lg='auto'>
            <div className='d-flex justify-content-start align-items-center w-100 mb-2 flex-wrap'>
              <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              {t('casinoSubCategory.filters.category')}
              </Form.Label>

              <Form.Select
                value={categoryFilter}
                onChange={(e) => {
                  setPage(1)
                  setCategoryFilter(e.target.value)
                }}
                style={{ minWidth: '230px' }}
              >
                <option value=''>{t('casinoSubCategory.filters.all')}</option>

                {casinoCategories && casinoCategories?.rows?.map((c) => (
                  <option key={c?.masterGameCategoryId} value={c?.masterGameCategoryId}>{c?.name?.EN}</option>
                ))}
              </Form.Select>
            </div>
          </Col> */}
          <Col xs='12' lg='auto' className='mt-2 mt-lg-0'>
            <div className='d-flex justify-content-start align-items-center w-100 flex-wrap'>
              <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
                {t('casinoSubCategory.filters.search')}
              </Form.Label>

              <Form.Control
                type='search'
                value={search}
                placeholder={'Search by title'}
                onChange={(event) => {
                  setPage(1)
                  setSearch(
                    event.target.value.replace(/[~`!$%@^&*#=)()><?]+/g, '')
                  )
                }}
                style={{ minWidth: '230px' }}
              />
            </div>
          </Col>
          <Col xs='12' lg='auto'>
            <div className='d-flex justify-content-start align-items-center w-100 flex-wrap'>
              <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
                {t('casinoSubCategory.filters.status')}
              </Form.Label>

              <Form.Select
                onChange={(e) => {
                  setPage(1)
                  setStatusFilter(e.target.value)
                }}
                value={statusFilter}
                style={{ minWidth: '230px' }}
              >
                <option value='all'>{t('casinoSubCategory.filters.all')}</option>
                <option value='true'>{t('casinoSubCategory.filters.active')}</option>
                <option value='false'>{t('casinoSubCategory.filters.inactive')}</option>
              </Form.Select>
            </div>
          </Col>

          {/* <Col xs='12' lg='auto' className='mt-2 mt-lg-0'>
          <div className='d-flex justify-content-start align-items-center w-100 flex-wrap'>

          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            {'AmountType'}
          </Form.Label>

          <Form.Select
            onChange={(e) => {
              setPage(1)
              setSelectedCurrency(e.target.value)}
            }
            value={selectedCurrency}
          >
            {coinTypeOptions && coinTypeOptions?.map(
              ({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            )}
          </Form.Select>
          </div>
        </Col> */}



          <Col
            className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
            style={{ width: "250px" }}
          >
            <Form.Label column="sm" className="mx-auto text-nowrap px-2">
              Start Date
            </Form.Label>
            <Datetime
              value={startDate}
              onChange={(date) => setStartDate(date)}
              timeFormat={false}
            />
          </Col>
          <Col
            className="col-lg-2 col-sm-6 col-12 mt-2 mt-sm-0"
            style={{ width: "250px" }}
          >
            <Form.Label column="sm" className="mx-auto text-nowrap px-2">
              End Date
            </Form.Label>
            <Datetime value={endDate} onChange={(date) => setEndDate(date)} timeFormat={false} />
          </Col>
        </Row>

        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  onClick={() => h.value !== '' && setOrderBy(h.value)}
                  style={{
                    cursor: 'pointer'
                  }}
                  className={
                    selected(h)
                      ? 'border-3 border border-blue'
                      : ''
                  }
                >
                  {t(h.labelKey)}{' '}
                  {selected(h) &&
                    (sort === 'ASC'
                      ? (
                        <FontAwesomeIcon
                          style={over ? { color: 'red' } : {}}
                          icon={faArrowCircleUp}
                          onClick={() => setSort('DESC')}
                          onMouseOver={() => setOver(true)}
                          onMouseLeave={() => setOver(false)}
                        />
                      )
                      : (
                        <FontAwesomeIcon
                          style={over ? { color: 'red' } : {}}
                          icon={faArrowCircleDown}
                          onClick={() => setSort('ASC')}
                          onMouseOver={() => setOver(true)}
                          onMouseLeave={() => setOver(false)}
                        />
                      ))}
                </th>
              ))}
            </tr>
          </thead>

          {loading ? (
            <tr>
              <td colSpan={10} className="text-center">
                <InlineLoader />
              </td>
            </tr>
          ) : (
            <tbody>
              {tournamentList && tournamentList.count > 0 ? (
                tournamentList?.rows?.map(
                  ({
                    tournamentId,
                    title,
                    entryAmount,
                    entryCoin,
                    startDate,
                    endDate,
                    isActive,
                    isEditable
                  }) => {
                    return (
                      <tr key={tournamentId}>
                        <td>{tournamentId}</td>

                        <td>{getDateTime(convertToTimeZone(startDate, timezoneOffset))}</td>
                        <td>{getDateTime(convertToTimeZone(endDate, timezoneOffset))}</td>

                        <td>
                          <Trigger message={title} id={title} />
                          <span
                            id={title}
                            style={{
                              width: '100px',
                              cursor: 'pointer'
                            }}
                            className='d-inline-block text-truncate'
                          >
                            {title}
                          </span>
                        </td>

                        <td>{entryAmount}</td>

                        <td>
                          {isActive
                            ? (
                              <span className='text-success'>{t('tournaments.activeStatus')}</span>
                            )
                            : (
                              <span className='text-danger'>{t('tournaments.inActiveStatus')}</span>
                            )}
                        </td>

                        <td>
                          {entryCoin}
                          {/* ? (
                            <span className='text-success'>{t('tournaments.activeStatus')}</span>
                          )
                          : (
                            <span className='text-danger'>{t('tournaments.inActiveStatus')}</span>
                          )} */}
                        </td>



                        <td>
                          <>
                            <Trigger message={'View'} id={tournamentId + 'view'} />
                            <Button
                              id={tournamentId + 'view'}
                              className='m-1'
                              size='sm'
                              variant='info'
                              onClick={() =>
                                navigate(
                                  `${AdminRoutes.TournamentDetails.split(':').shift()}${tournamentId}`
                                )}
                              hidden={isHidden({ module: { key: 'Bonus', value: 'R' } })}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </Button>

                            <Trigger message='Edit' id={tournamentId + 'edit'} />
                            <Button
                              id={tournamentId + 'edit'}
                              className='m-1'
                              size='sm'
                              variant='warning'
                              hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                              onClick={() => {
                                navigate(
                                  `${AdminRoutes.TournamentEdit.split(':').shift()}${tournamentId}`
                                )
                              }}
                              disabled={isEditable}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>

                            {!isActive
                              ? (<>
                                <Trigger message='Set Status Active' id={tournamentId + 'active'} />
                                <Button
                                  id={tournamentId + 'active'}
                                  className='m-1'
                                  size='sm'
                                  variant='success'
                                  hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                  onClick={() =>
                                    handleShow(tournamentId, isActive)}
                                  disabled={isEditable}
                                >
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </Button>
                              </>
                              )
                              : (<>
                                <Trigger message='Set Status In-Active' id={tournamentId + 'inactive'} />
                                <Button
                                  id={tournamentId + 'inactive'}
                                  className='m-1'
                                  size='sm'
                                  variant='danger'
                                  hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                  onClick={() =>
                                    handleShow(tournamentId, isActive)}
                                  disabled={isEditable}
                                >
                                  <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                              </>
                              )}

                            {/* <Trigger message='Delete' id={tournamentId + 'delete'} />
                          <Button
                            id={tournamentId + 'delete'}
                            className='m-1'
                            size='sm'
                            variant='danger'
                            hidden={isHidden({ module: { key: 'CasinoManagement', value: 'D' } })}
                            onClick={() => handleDeleteModal(tournamentId)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button> */}
                          </>
                        </td>
                      </tr>
                    )
                  }
                )
              ) : (
                <tr>
                  <td colSpan={7} className='text-danger text-center'>
                    {t('tournaments.noDataFound')}
                  </td>
                </tr>
              )
              }
            </tbody>
          )}

        </Table>
        {tournamentList?.count !== 0 &&
          (
            <PaginationComponent
              page={tournamentList?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
      </>
      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          loading={updateloading}
        />
      )}

      {deleteModalShow &&
        (
          <DeleteConfirmationModal
            deleteModalShow={deleteModalShow}
            setDeleteModalShow={setDeleteModalShow}
            handleDeleteYes={handleDeleteYes}
          />)}

    </>
  )
}

export default Tournaments