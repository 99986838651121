import React, { useState } from 'react';
import { Row, Col, Card, Table, Button, Form } from '@themesberg/react-bootstrap';
import { InlineLoader } from '../../components/Preloader';
import useDashboardDataListing from './hooks/useDashboardData';
import DashBoardFilters from './DashBoardFilters';
import { tableData, timeZones, totalTablesList } from './constants';
import DashboardCharts from './DashboardCharts';
import LoginDataTable from './tables/LoginDataTable';
import CustomerTable from './tables/CustomerDataTable';
import EconomyTable from './tables/EconomyTable';
import TransactionTable from './tables/TransactionsTable';

const Dashboard = () => {
  const [economicDataAccordionOpen, setEconomicDataAccordionOpen] = useState(false);
  const [transactionDataAccordianOpen, setTransactionDataAccordianOpen] = useState(false);
  const {
    reportData, customerData, economyData, transactionData, dashboardData,
    reportRefetch, customerRefetch, transactionRefetch, economyRefetch,dashboardReportRefetch,
    playerType,
    setPlayerType,setTimeStamp,timeStamp,
    t,timeZoneCode,setStartDate,setEndDate,
    transactionLoading,reportLoading, customerLoading, economyLoading,startDate,endDate,
    isDashboardReportRefetching,
    isReportRefetching,
    isCustomerRefetching,
    isEconomyRefetching,
    isTransactionRefetching,
  } = useDashboardDataListing(economicDataAccordionOpen, transactionDataAccordianOpen);

  return (
    <>
      <Row className='d-flex'>
        <Col sm={8} >
          <h3>{t('title')}</h3>
        </Col>
        <Col sm={4} className='pb-2'>
          <Form.Select
            value={timeStamp}
            onChange={(event) => {
              setTimeStamp(event.target.value);
            }}
          >
            {timeZones?.map(({ labelKey, value ,code }) => {
            return (
              <option key={value} value={value}>
                {t(labelKey)} ({code}) {value}
              </option>
            );
          })}
          </Form.Select>
        </Col>
      </Row>
      <Card className='p-2 mb-2'>
        <DashboardCharts customerData={customerData} loginData={reportData} economyData={economyData} transactionData={transactionData} data={dashboardData} />
        <DashBoardFilters
          setPlayerType={setPlayerType}
          playerType={playerType}
          t={t}
          setStartDate={setStartDate} setEndDate={setEndDate}
          startDate = {startDate} endDate={endDate}
          timeZoneCode={timeZoneCode}
          reportRefetch={reportRefetch}
          customerRefetch={customerRefetch}
          transactionRefetch={transactionRefetch}
          economyRefetch={economyRefetch}
          dashboardReportRefetch={dashboardReportRefetch}
          economicDataAccordionOpen={economicDataAccordionOpen}
          transactionDataAccordianOpen={transactionDataAccordianOpen}
          isDashboardReportRefetching={isDashboardReportRefetching}
          isReportRefetching={isReportRefetching}
          isCustomerRefetching={isCustomerRefetching}
          isEconomyRefetching={isEconomyRefetching}
          isTransactionRefetching={isTransactionRefetching}
        />
        <LoginDataTable tableKey='loginData' reportLoading={reportLoading} reportData={reportData} t={t}/>
        <hr></hr>
        <CustomerTable tableKey='customerDataKeys'  customerLoading={customerLoading} customerData={customerData} t={t} />
        <hr></hr>
        <EconomyTable tableKey='loginData' accordionOpen={economicDataAccordionOpen} setAccordionOpen={setEconomicDataAccordionOpen} 
        economyLoading={economyLoading} economyData={economyData} t={t}/>
        <TransactionTable tableKey='customerDataKeys' accordionOpen={transactionDataAccordianOpen} setAccordionOpen={setTransactionDataAccordianOpen} 
        transactionLoading={transactionLoading} transactionData={transactionData} t={t}/>
      </Card>
    </>
  );
};
export default Dashboard;
