import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserAlt,
  faAward,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../../../utils/dateFormatter';

const filterData = (data, label) => data.filter((row) => row.label === label);
const DashboardBox = ({ icon, label, data, boxClass }) => {
  const validData = data != null ? data : 0;
  return (
  <div className={`dashboard-box ${boxClass}`}>
    <div className='new-icon'>
      <FontAwesomeIcon icon={icon} />
    </div>
    <label>{label}</label>
    <div className='live-report-data'>{formatNumber(validData)}</div>    
  </div>
)};

const MultiChartContainer = ({ data }) => {
  
  return (
    <>
          <div className='dashboard-boxes-container'>
        <DashboardBox
          icon={faUserAlt}
          label='Today SC Staked'
          data={data?.DASHBOARD_REPORT?.scStakedTodayCount}
          boxClass='sc-stack'
        />
        <DashboardBox
          icon={faAward}
          label='Today SC wins'
          data={data?.DASHBOARD_REPORT?.scWinTodayCount}
          boxClass='sc-win'
        />
        <DashboardBox
          icon={faUserAlt}
          label='Today GGR SC'
          data={data?.DASHBOARD_REPORT?.scGgr}
          boxClass='scr-sc'
        />
        <DashboardBox
          icon={faDollarSign}
          label='SC Awarded Total'
          data={data?.DASHBOARD_REPORT?.scAwardedTotalSumForToday}
          boxClass='usc-balance'
        />
        <DashboardBox
          icon={faDollarSign}
          label='GC Awarded Total'
          data={data?.DASHBOARD_REPORT?.gcAwardedTotalSumForToday}
          boxClass='rsc-balance'
        />
         <DashboardBox
          icon={faDollarSign}
          label='Today Net GGR SC'
          data={data?.DASHBOARD_REPORT?.netScGgr}
          boxClass='ggrsc-balance'
        />
         {/* <DashboardBox
          icon={faDollarSign}
          label='Total Vault SC'
          data={data?.DASHBOARD_REPORT?.
            totalVaultScCoin}
         boxClass='sc-stack'
        />
         <DashboardBox
          icon={faDollarSign}
          label='Total Wallet SC'
          data={data?.DASHBOARD_REPORT?.
            totalWalletScCoin}
          boxClass='sc-win'
        /> */}
      </div>
    </>
  );
};

export default MultiChartContainer;