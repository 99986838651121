import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  Button,
  Col,
  Row,
  Table,
} from '@themesberg/react-bootstrap';
import React from 'react';
import Preloader from '../../components/Preloader';
import EditUploadBanner from './EditUploadBanner';
import useBannerManagement from './useBannerManagement';
import Trigger from '../../components/OverlayTrigger';
import { bannerType, tableHeaders } from './constants';
import { DeleteConfirmationModal } from '../../components/ConfirmationModal';
import useCheckPermission from '../../utils/checkPermission';
import PaginationComponent from '../../components/Pagination';
import {  
  faArrowCircleUp,
  faArrowCircleDown,
} from '@fortawesome/free-solid-svg-icons'
import BannerViewer from './BannerViewer';

const BannerManagement = () => {
  const {
    t,
    pageBannerId,
    loading,
    handleCreateEdit,
    type,
    data,
    setShow,
    show,
    createUpdate,
    bannersList,
    submitLoading,
    handleDeleteModal,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    page,
    setLimit,
    limit,
    setPage,
    totalPages,
    deleteLoading,
    selected,
    sort,
    setSort,
    over,
    setOver,
    setOrderBy
  } = useBannerManagement();

  const { isHidden } = useCheckPermission();

  if (loading) return <Preloader />;



  return (
    <>
      <>
        <Row>
          <Col>
            <h3>{t('casinoBannerManagement.title')}</h3>
          </Col>

          <Col xs='auto'>
            <div className='d-flex justify-content-end align-items-center'>
              <Button
                hidden={isHidden({ module: { key: 'Banner', value: 'C' } })}
                variant='success'
                size='sm'
                onClick={() => handleCreateEdit('Create', {})}
              >
                {t('casinoBannerManagement.uploadButton')}
              </Button>
            </div>
          </Col>
        </Row>

        <Accordion>
          <Accordion.Item>
            <Accordion.Body>
              <Table
                bordered
                striped
                responsive
                hover
                size='sm'
                className='text-center mt-2'
              >
                <thead className='thead-dark'>
                  {/* <tr>
                    {[
                      t('casinoBannerManagement.headers.id'),
                      t('casinoBannerManagement.headers.name'),
                      t('casinoBannerManagement.headers.bannerMobile'),
                      t('casinoBannerManagement.headers.bannerDesktop'),
                      // t('casinoBannerManagement.headers.status'),
                      t('casinoBannerManagement.headers.pageName'),
                      t('casinoBannerManagement.headers.action'),
                    ].map((h) => (
                      <th key={h}>{h}</th>
                    ))}
                  </tr> */}

                  <tr>
                    {tableHeaders.map((h, idx) => (
                      <th
                        key={idx}
                        onClick={() => h.value !== '' && (setOrderBy(h.value) || setSort(sort === 'ASC' ? 'DESC' : 'ASC'))}
                        style={{ cursor: 'pointer' }}
                        className={selected(h) ? 'border-3 border border-blue' : ''}
                      >
                        {t(h.labelKey)}{' '}
                        {selected(h) && (
                          sort === 'ASC' ? (
                            <FontAwesomeIcon
                              style={over ? { color: 'red' } : {}}
                              icon={faArrowCircleUp}
                              onClick={() => setSort('DESC')}
                              onMouseOver={() => setOver(true)}
                              onMouseLeave={() => setOver(false)}
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={over ? { color: 'red' } : {}}
                              icon={faArrowCircleDown}
                              onClick={() => setSort('ASC')}
                              onMouseOver={() => setOver(true)}
                              onMouseLeave={() => setOver(false)}
                            />
                          )
                        )}
                      </th>
                    ))}
                  </tr>

                </thead>

                <tbody>
                  {bannersList &&
                    bannersList?.rows?.map((item) => {
                      return (
                        <tr key={item.pageBannerId}>
                          <td>{item.pageBannerId}</td>
                          <td>{item.name}</td>
                          <td>
                            <BannerViewer thumbnailUrl={item.mobileImageUrl}  />
                          </td>

                          <td>
                          <BannerViewer thumbnailUrl={item.desktopImageUrl}  />
                          </td>
                          {/* {
                            <td>{item.isActive ? "True" : "False"}</td>
                          } */}
                          <td>{item.pageName}</td>
                          {(!isHidden({ module: { key: 'Banner', value: 'U' } }) || !isHidden({ module: { key: 'Banner', value: 'T' } }))
                            ? (
                              <td>
                                <Trigger
                                  message={t(
                                    'casinoBannerManagement.updateMessage'
                                  )}
                                  id={item.pageBannerId + 'warn'}
                                />
                                <Button
                                  id={item.pageBannerId + 'warn'}
                                  size='sm'
                                  variant='warning'
                                  onClick={() => {
                                    handleCreateEdit('Update', item);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <Trigger
                                  message={t('casinoBannerManagement.delete')}
                                  id={item.pageBannerId + 'delete'}
                                />
                                <Button
                                  id={item.pageBannerId + 'delete'}
                                  className='m-1'
                                  size='sm'
                                  variant='danger'
                                  hidden={isHidden({
                                    module: { key: 'Bonus', value: 'D' },
                                  })}
                                  onClick={() =>
                                    handleDeleteModal(item.pageBannerId)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </td>
                            ) : "NA"}
                        </tr>
                      );
                    })}

                  {(!bannersList || bannersList?.count === 0) && (
                    <tr>
                      <td colSpan={4} className='text-danger text-center'>
                        {t('casinoBannerManagement.noDataFound')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {bannersList?.count !== 0 &&
                (
                  <PaginationComponent
                    page={bannersList?.count < page ? setPage(1) : page}
                    totalPages={totalPages}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                  />

                )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
      {deleteModalShow && (
        <DeleteConfirmationModal
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
          handleDeleteYes={handleDeleteYes}
          loading={deleteLoading}
        />
      )}
      <EditUploadBanner
        bannersList={bannersList}
        pageBannerId={pageBannerId}
        t={t}
        type={type}
        data={data}
        show={show}
        setShow={setShow}
        loading={submitLoading}
        createUpdate={createUpdate}
      />
    </>
  );
};

export default BannerManagement;
