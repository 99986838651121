import { faComment, faCommentAlt, faImage, faListAlt, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faChartPie, faCompass, faListCheck, faGamepad ,faWrench, faMoneyCheckDollar, faDollarSign,faVault, faList12, faMailBulk, faPeopleGroup, faPhotoVideo, faShieldAlt, faShippingFast, faStream, faUserAlt, faUsers, faAward, faCreditCard, faMoneyBillWave, faFileCsv, faBell, faPager, faFileInvoice , faWallet } from '@fortawesome/free-solid-svg-icons';
import { AdminRoutes, AffiliateRoute } from '../routes';
import { faPagelines } from '@fortawesome/free-brands-svg-icons';

export const navItems = [
  {
    titleKey: 'dashboard',
    link: AdminRoutes.Dashboard,
    icon: faChartPie,
    permissionLabel: 'Report',
    inSidePermissionLabel: 'DR'
  },
  {
    titleKey: 'profile',
    link: AdminRoutes.Profile,
    icon: faUserAlt
  },
  // {
  //   titleKey: 'emailTemplate',
  //   link: AdminRoutes.EmailTemplates,
  //   icon: faMailBulk,
  //   permissionLabel: 'EmailTemplate'
  // },
  {
    titleKey: 'staff',
    link: AdminRoutes.Staff,
    icon: faPeopleGroup,
    permissionLabel: 'Admins'
  },
  // {
  //   titleKey: 'countries',
  //   link: AdminRoutes.Countries,
  //   icon: faCompass,
  //   permissionLabel: 'Admins'
  // },
  {
    titleKey: 'players',
    link: AdminRoutes.Players,
    icon: faUsers,
    permissionLabel: 'Users'
  },
  {
    titleKey: 'casinoManagement',
    path: 'casino-management',
    icon: faListAlt,
    permissionLabel: 'CasinoManagement',
    isCollapsable: true,
    options: [
      {
        titleKey: 'Aggregators',
        link: AdminRoutes.Aggregators,
        icon: faUserCircle,
        permissionLabel: 'CasinoManagement'
      },
      {
        titleKey: 'providers',
        link: AdminRoutes.CasinoProviders,
        icon: faUserCircle,
        permissionLabel: 'CasinoManagement'
      },
      // {
      //   titleKey: 'categories',
      //   link: AdminRoutes.CasinoCategories,
      //   icon: faStream,
      //   permissionLabel: 'CasinoManagement'
      // },
      {
        titleKey: 'subCategories',
        link: AdminRoutes.CasinoSubCategories,
        icon: faList12,
        permissionLabel: 'CasinoManagement'
      },
      {
        titleKey: 'games',
        link: AdminRoutes.CasinoGames,
        icon: faGamepad,
        permissionLabel: 'CasinoManagement'
      },
     
    ]
  },
  {
    titleKey: 'bannerManagement',
    link: AdminRoutes.BannerManagement,
    icon: faPhotoVideo,
    permissionLabel: 'Banner'
  },
  {
    titleKey: 'transactions',
    path: 'transactions',
    icon: faMoneyCheckDollar,
    permissionLabel: 'Transactions',
    isCollapsable: true,
    options: [
      {
        titleKey: 'transactionsBanking',
        link: AdminRoutes.BankingTransactions,
        icon: faDollarSign,
        permissionLabel: 'Transactions'
      },
      {
        titleKey: 'casinoTransactions',
        link: AdminRoutes.CasinoTransactions,
        icon: faCreditCard,
        permissionLabel: 'Transactions'
      },
      {
        titleKey: 'withdrawRequests',
        link: AdminRoutes.WithdrawRequest,
        icon: faMoneyBillWave,
        permissionLabel: 'Transactions'
      },
      {
        titleKey: 'Vault',       
        link: AdminRoutes.VaultRequest,
        icon: faVault,
        permissionLabel: 'Transactions'
      }
    ]
  },
  {
    titleKey: 'packages',
    link: AdminRoutes.Packages,
    icon: faShippingFast,
    permissionLabel: 'Package'
  },
  {
    titleKey: 'Tournaments',
    link: AdminRoutes.Tournament,
    icon: faPager,
    permissionLabel: 'Tournaments'
  },
  {
    titleKey: 'Tiers',
    link: AdminRoutes.Tier,
    icon: faPager,
    permissionLabel: 'Tiers'
  },
  {
    titleKey: 'Giveaways',
    link: AdminRoutes.Raffle,
    icon: faPager,
    permissionLabel: 'Raffles'
  },
  // {
  //   titleKey: 'SpinWheel',
  //   link: AdminRoutes.SpinWheel,
  //   icon: faPager,
  //   permissionLabel: 'Admins'
  // },
  // {
  //   titleKey: 'Raffle Payout',
  //   link: AdminRoutes.RafflePayout,
  //   icon: faPager,
  //   permissionLabel: 'Raffles'
  // },
  {
    titleKey: 'cms',
    path: 'cms',
    icon: faCommentAlt,
    permissionLabel: 'CMS',
    isCollapsable: true,
    options: [
      // {
      //   titleKey: 'pages',
      //   link: AdminRoutes.ContentPagesListing,
      //   icon: faPager,
      //   permissionLabel: 'CMS'
      // },
      {
        titleKey: 'dynamicCms',
        link: AdminRoutes.CmsListing,
        icon: faShieldAlt,
        permissionLabel: 'CMS'
      }
    ]
  },
  // {
  //   titleKey: 'cms',
  //   link: AdminRoutes.CmsListing,
  //   icon: faShieldAlt,
  //   permissionLabel: 'CMS'
  // },
  {
    titleKey: 'bonus',
    link: AdminRoutes.BonusListing,
    icon: faAward,
    permissionLabel: 'Bonus'
  },
  // {
  //   titleKey: 'referralBonus',
  //   link: AdminRoutes.ReferralBonusListing,
  //   icon: faAward,
  //   permissionLabel: 'Bonus'
  // },
  {
    titleKey: 'Affiliate',
    link: AdminRoutes.Affiliate,
    icon: faUsers,
    permissionLabel: 'Affiliates'
  },  
  {
    titleKey: 'promoCode',
    path: 'promotion-bonus',
    icon: faListAlt,
    permissionLabel: 'Promocode',
    isCollapsable: true,
    options: [
      {
        titleKey: 'Affiliate Promo Codes',
        link: AdminRoutes.PromotionBonus,
        icon: faImage,
        permissionLabel: 'PromotionBonus'
      },
      {
        titleKey: 'Purchase Promo Codes',
        link: AdminRoutes.PromoCodeBonus,
        icon: faImage,
        permissionLabel: 'Promocode'
      },     
    ]
  }, 
 
]

export const affiliateNavLink = [
  {
    titleKey: 'Players',
    link: AffiliateRoute.AffiliatePlayers,
    icon: faUsers,
    permissionLabel: 'AffiliatePlayers',
  },
  {
    titleKey: 'Profile',
    link: AffiliateRoute.AffiliateProfile,
    icon: faUserAlt,
  }
]