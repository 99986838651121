import styled from 'styled-components';

export const RedeemTableContainer = styled.div`
    .table-wrapper .table-responsive {
        max-height: 815px;
        overflow-y: auto;
        position: relative;
    }
    .table-wrapper .table-scroll{
        position: sticky;
        top: -1px;
    }
`
