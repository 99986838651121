import React from "react";
import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  Form,
} from "@themesberg/react-bootstrap";
import usePromotionListing from "./hooks/usePromotionLisiting";
import { tableHeaders } from "./constant";
import { formatDateMDY } from "../../utils/dateFormatter";
import Trigger from "../../components/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown, faArrowAltCircleUp, faCheckSquare, faEdit, faEye, faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { AdminRoutes } from "../../routes";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../components/Pagination";
import { InlineLoader } from "../../components/Preloader";
import { ConfirmationModal, DeleteConfirmationModal } from "../../components/ConfirmationModal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { searchRegEx } from "../../utils/helper";
import useCheckPermission from "../../utils/checkPermission";
const PromotionBonus = () => {
  const navigate = useNavigate()
  const { promotionList, selected, loading, page, totalPages, setPage, limit, setLimit, handleShow, show, setShow, handleYes, active,
    handleDelete, handleDeleteYes, deleteModalShow, setDeleteModalShow, setSearch, search, setOrderBy, sort, over, setOver, setSort, deleteLoading, updateloading }
    = usePromotionListing();
  const { isHidden } = useCheckPermission()
  return (
    <>
      <Row className="mb-2">
        <Col>
          <h3>Affiliate Promo Codes</h3>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Button
              variant="success"
              hidden={isHidden({ module: { key: 'PromotionBonus', value: 'C' } })}
              size="sm"
              style={{ marginRight: "10px" }}
              onClick={() => {
                navigate(AdminRoutes.PromotionBonusCreate);
              }}
            >
              Create
            </Button>
          </div>
        </Col>
        <Col xs={12}>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '8px' }}>
            Search
          </Form.Label>

          <Form.Control
            type='search'
            placeholder='Search Promocode'
            value={search}
            style={{ maxWidth: '330px', marginRight: '10px', marginTop: '5px' }}
            onChange={(event) => {
              setPage(1)
              const mySearch = event.target.value.replace(searchRegEx, '')
              setSearch(mySearch)
            }}
          />
        </Col>
      </Row>
      <Table
        bordered
        striped
        responsive
        hover
        size="sm"
        className="text-center mt-4"
      >
        <thead className="thead-dark">
          <tr>
            {tableHeaders.map((h, idx) => (
              <th
                key={idx}
                onClick={() => h.value !== "" && setOrderBy(h.value)}
                style={{
                  cursor: "pointer",
                }}
                className={selected(h) ? "border-3 border border-blue" : ""}
              >
                {h.labelKey}{" "}
                {selected(h) &&
                  (sort === "ASC" ? (
                    <FontAwesomeIcon
                      style={over ? { color: "red" } : {}}
                      icon={faArrowAltCircleUp}
                      onClick={() => setSort("DESC")}
                      onMouseOver={() => setOver(true)}
                      onMouseLeave={() => setOver(false)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={over ? { color: "red" } : {}}
                      icon={faArrowAltCircleDown}
                      onClick={() => setSort("ASC")}
                      onMouseOver={() => setOver(true)}
                      onMouseLeave={() => setOver(false)}
                    />
                  ))}
              </th>
            ))}
          </tr>
        </thead>

        {loading ? (
          <tr>
            <td colSpan={10} className="text-center">
              <InlineLoader />
            </td>
          </tr>
        ) : (
          <tbody>
            {promotionList?.count > 0 ? (
              promotionList?.promoCodes?.map(
                ({
                  promocodeId,
                  promocode,
                  affiliateId,
                  bonusSc,
                  bonusGc,
                  validTill,
                  isActive,
                }) => {
                  return (
                    <tr key={promocodeId}>
                      <td>{promocodeId}</td>
                      <td>{promocode}</td>
                      <td>{affiliateId}</td>
                      <td>{bonusSc}</td>
                      <td>{bonusGc}</td>
                      <td>{validTill === null ? "-" : formatDateMDY(validTill)}</td>
                      <td>{isActive == true ? "True" : "False"}</td>
                      {(!isHidden({ module: { key: 'PromotionBonus', value: 'U' } }) || !isHidden({ module: { key: 'PromotionBonus', value: 'T' } }))
                        ? (
                          <td>
                            <Trigger message={"View"} id={promocodeId + "view"} />
                            <Button
                              id={promocodeId + "view"}
                              className="m-1"
                              size="sm"
                              variant="info"
                              onClick={() =>
                                navigate(
                                  `${AdminRoutes.PromotionBonusView.split(
                                    ":"
                                  ).shift()}${promocodeId}`
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                            <Trigger message="Edit" id={promocodeId + "edit"} />
                            <Button
                              id={promocodeId + "edit"}
                              hidden={isHidden({ module: { key: 'PromotionBonus', value: 'U' } })}
                              className="m-1"
                              size="sm"
                              variant="warning"
                              onClick={() =>
                                navigate(
                                  `${AdminRoutes.PromotionBonusEdit.split(
                                    ":"
                                  ).shift()}${promocodeId}`
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Trigger message="Delete" id={promocodeId + "delete"} />
                            <Button
                              id={promocodeId + "delete"}
                              hidden={isHidden({ module: { key: 'PromotionBonus', value: 'U' } })}
                              className="m-1"
                              size="sm"
                              variant="warning"
                              onClick={() => handleDelete(promocodeId)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                            {!isActive ? (
                              <>
                                <Trigger
                                  message="Set Status Active"
                                  id={promocodeId + "active"}
                                />
                                <Button
                                  id={promocodeId + "active"}
                                  hidden={isHidden({ module: { key: 'PromotionBonus', value: 'U' } })}
                                  className="m-1"
                                  size="sm"
                                  variant="success"
                                  onClick={() => handleShow(promocodeId, isActive)}
                                >
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </Button>
                              </>
                            ) : (
                              <>
                                <Trigger
                                  message="Set Status In-Active"
                                  id={promocodeId + "inactive"}
                                />
                                <Button
                                  id={promocodeId + "inactive"}
                                  hidden={isHidden({ module: { key: 'PromotionBonus', value: 'U' } })}
                                  className="m-1"
                                  size="sm"
                                  variant="danger"
                                  onClick={() => handleShow(promocodeId, isActive)}
                                >
                                  <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                              </>
                            )}
                          </td>
                        ) : "NA"}
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td colSpan={7} className="text-danger text-center">
                  No Data Found
                </td>
              </tr>
            )
            }
          </tbody>
        )}
      </Table>
      {promotionList?.count !== 0 && (
        <PaginationComponent
          page={promotionList?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          loading={updateloading}
        />
      )}
      {deleteModalShow &&
        (
          <DeleteConfirmationModal
            deleteModalShow={deleteModalShow}
            setDeleteModalShow={setDeleteModalShow}
            handleDeleteYes={handleDeleteYes}
            loading={deleteLoading}
          />)
      }
    </>
  );
};

export default PromotionBonus;
