import React, { useState } from "react";
import { Table, Button } from "@themesberg/react-bootstrap";
import { useTranslation } from "react-i18next";
import { InlineLoader } from "../Preloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import PaginationComponent from "../Pagination";
import { TRANSACTION_STATUS, tableHeaders } from "./constants";
import { getDateTime } from "../../utils/dateFormatter";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "../Toast";
import Trigger from "../OverlayTrigger";
import ModalView from "../Modal";
import RemarksModal from "../../pages/PlayerDetails/components/Verification/RemarksModal";
import { usePaymentRefundMutation } from "../../reactQuery/hooks/customMutationHook";
import {
  convertToTimeZone,
  getFormattedTimeZoneOffset,
} from "../../utils/helper";
import { getItem } from "../../utils/storageUtils";
import { timeZones } from "../../pages/Dashboard/constants";
import { MoreDetail } from "../ConfirmationModal";

const VaultList = ({
  page,
  setLimit,
  limit,
  setPage,
  totalPages,
  loading,
  data,
  isAllUser,
  vaultRefetch,
}) => {
  // const [show, setShow] = useState(false);
  // const [openModal, setOpenModal] = useState(false)
  // const [userId, setUserId] = useState(false)
  return (
    <>
      <Table
        bordered
        striped
        responsive
        hover
        size="sm"
        className="text-center mt-4"
      >
        <thead className="thead-dark">
          <tr>
            {["ID", "EMAIL", "USERNAME", " VAULT GC COINS", "VAULT SC COINS"].map((h) => (
              <th key={h}>{h}</th>
            ))}
          </tr>
        </thead>
        {loading ?
          (<tr>
            <td colSpan={10} className="text-center">
              <InlineLoader />
            </td>
          </tr>) : 
          (
            <tbody>
              {data && data?.rows?.length > 0 ? (
                data?.rows?.map(
                  ({
                    ownerId,
                    User,
                    vault_gc_coin,
                    scCoin,
                    total_vault_sc_coin,
                  }) => {
                    return (
                      <tr key={ownerId} className="text-center">
                        <td>{ownerId}</td>

                        <td><Link to={`/admin/player-details/${ownerId}`}>{User?.email}</Link></td>
                        <td><Link to={`/admin/player-details/${ownerId}`}>{User?.username}</Link></td>
                        <td>{vault_gc_coin ? vault_gc_coin : "0"}</td>
                        <td>{total_vault_sc_coin ? total_vault_sc_coin : "0"}</td>
                      </tr>
                    );
                  }
                )
              ) : (
                <tr>
                  <td colSpan={9} className="text-danger text-center">
                    No data Found
                  </td>
                </tr>
              )}
            </tbody>)}
      </Table>
      
      {data?.count !== 0 && (
        <PaginationComponent
          page={data?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </>
  );
};

export default VaultList;
