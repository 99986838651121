import { Button, Col, Row, Form } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import Preloader from "../../../components/Preloader";
import Trigger from "../../../components/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "../../../components/Toast";
import { statusTypeOptions, transactionTypeOptions } from "../constants";
import {
  convertTimeZone,
  getFormattedTimeZoneOffset,
  onDownloadCsvClick,
} from "../../../utils/helper";
import { getItem } from "../../../utils/storageUtils";
import { timeZones } from "../../Dashboard/constants";
import DatePicker from "react-datepicker";
import Datetime from "react-datetime";
import VaultList from "../../../components/VaultList";
import useVaultList from "../hooks/useVaultList";

const Vault = ({ email, isAllUser }) => {
  const {
    t,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    vaultRefetch,
    vaultData,
    loading,
    getCsvDownloadUrl,
    search,
    setSearch,
    setCsvDownload,
    setUsername,
    username,
  } = useVaultList(email);
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleDownloadClick = async () => {
    try {
      let filename = "Vault_Data";

      if (search) {
        filename += `_${search}`;
      }
      if (username) {
        filename += `_${username}`;
      }
      setDownloadInProgress(true);
      const url = getCsvDownloadUrl();
      await onDownloadCsvClick(url, filename);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    } finally {
      setDownloadInProgress(false);
    }
  };

  const resetFilters = () => {
    setSearch("");
    setLimit(15);
    setPage(1), setUsername("");
  };

  return (
    <>
      {
        <Row className="mb-3">
          <Col sm={12}>
            <h3>Vault</h3>
          </Col>
        </Row>
      }
      <Row className="w-100 m-auto">
        <Col xs="12" sm="6" lg="3" className="mb-3">
          <Form.Label
            style={{
              marginBottom: "0",
              marginRight: "15px",
              marginTop: "5px",
            }}
          >
            {t("transactions.filters.search")}
          </Form.Label>

          <Form.Control
            type="search"
            value={search}
            placeholder="Search By Email"
            onChange={(event) => {
              setPage(1);
              setSearch(event.target.value.replace(/[~`%^#)()><?]+/g, "").trim());
            }}
          />
        </Col>

        <Col className="col-lg-3 col-sm-6 col-12">
          <Form.Label
            style={{
              marginBottom: "0",
              marginRight: "15px",
              marginTop: "5px",
            }}
          >
            User Name
          </Form.Label>

          <Form.Control
            type="text"
            value={username}
            placeholder="Search by Username"
            onChange={(event) => {
              setPage(1);
              setUsername(
                event.target.value.replace(/[~`%^#)()><?]+/g, "").trim()

              );
            }}
          />
        </Col>

        <Col
          xs="12"
          sm="6"
          lg="1"
          className="d-flex align-items-end mt-2 mt-sm-0 mb-0 mb-sm-3"
        >
          <Trigger message="Reset Filters" id={"redo"} />
          <Button
            id={"redo"}
            variant="success"
            className=""
            onClick={resetFilters}
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>
          <Trigger message="Download as CSV" id={"csv"} />
          <Button
            id={"csv"}
            variant="success"
            style={{ marginLeft: "10px" }}
            disabled={vaultData?.count === 0 || downloadInProgress}
            onClick={handleDownloadClick}
          >
            {downloadInProgress ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <FontAwesomeIcon icon={faFileDownload} />
            )}
          </Button>
        </Col>
      </Row>
      <VaultList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        data={vaultData}
        loading={loading}
        isAllUser={isAllUser}
        vaultRefetch={vaultRefetch}
      />
    </>
  );
};

export default Vault;
