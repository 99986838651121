import { Col, InputGroup, Row, Form as BForm, Button, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { siteConfigSchema } from '../schema'

const SiteConfiguration = ({
  details,
  setEditable,
  editable,
  updateData,
  loading,
  preview,
  handleImagePreview
}) => {
  const { t } = useTranslation(['profile'])
  return (
    <>
      <Row className='my-n2 pt-3'>
        <Col sm={12} className='my-2'>
          <div className='text-right m-n1'>
            <button
              type='button' className='m-1 btn btn-warning'
              onClick={() => {
                setEditable(true)
              }}
            >{t('editButton')}
            </button>
          </div>
        </Col>

        {details &&
          <Formik
            enableReinitialize
            initialValues={{
              siteName: details?.siteConfig?.find((obj) => obj.key === 'SITE_NAME').value || '',
              siteUrl: details?.siteConfig?.find((obj) => obj.key === 'ORIGIN').value || '',
              supportEmailAddress: details?.siteConfig?.find((obj) => obj.key === 'SUPPORT_EMAIL_ADDRESS').value || '',
              minRedeemableCoins: details?.siteConfig?.find((obj) => obj.key === 'MINIMUM_REDEEMABLE_COINS').value || '',
              maxRedeemableCoins: details?.siteConfig?.find((obj) => obj.key === 'MAXIMUM_REDEEMABLE_COINS').value || '',
              scToGcRate: details?.siteConfig?.find((obj) => obj.key === 'SC_TO_GC_RATE').value || '',
              xpScToGcRate: details?.siteConfig?.find((obj) => obj.key === 'XP_SC_TO_GC_RATE').value || '',
              siteLogo: details?.siteConfig?.find((obj) => obj.key === 'LOGO_URL').value || '',
              minScSpinLimit: details?.siteConfig?.find((obj) => obj.key === 'MINIMUM_SC_SPIN_LIMIT').value || '',
              minGcSpinLimit: details?.siteConfig?.find((obj) => obj.key === 'MINIMUM_GC_SPIN_LIMIT').value || '',
              gcVaultPercentage: details?.siteConfig?.find((obj) => obj.key === 'MAX_GC_VAULT_PER').value || '',
              scVaultPercentage: details?.siteConfig?.find((obj) => obj.key === 'MAX_SC_VAULT_PER').value || ''
            }}
            validationSchema={siteConfigSchema(t)}
            onSubmit={(
              formValues
            ) => {
              updateData({ data: formValues })
            }}
          >
            {({ errors, values, handleChange, handleSubmit, handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <Row lg={2} md={2} sm={2}>
                   
                    <Col className="mb-3 col-lg-6 col-6">
                      
                      <div className='mb-3 bg-light py-2 px-3 rounded'>
                        <label className='fw-bold'>{t('inputFields.minRedeemableCoins.label')}</label>
                          <span className='mb-0'>
                              <InputGroup>
                              <BForm.Control
                                  type={'number'}
                                  autoComplete="off"
                                  name={'minRedeemableCoins'}
                                  disabled={!editable}
                                  value={values?.minRedeemableCoins}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                              </InputGroup>

                              <ErrorMessage
                              component='div'
                              name={'minRedeemableCoins'}
                              className='text-danger'
                              />
                          </span>
                      </div>
                      
                    </Col>
                    <Col className="mb-3 col-lg-6 col-6">
                      
                      <div className='mb-3 bg-light py-2 px-3 rounded'>
                        <label className='fw-bold'>{t('inputFields.maxRedeemableCoins.label')}</label>
                          <span className='mb-0'>
                              <InputGroup>
                              <BForm.Control
                                  type={'number'}
                                  autoComplete="off"
                                  name={'maxRedeemableCoins'}
                                  disabled={!editable}
                                  value={values?.maxRedeemableCoins}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                              </InputGroup>

                              <ErrorMessage
                              component='div'
                              name={'maxRedeemableCoins'}
                              className='text-danger'
                              />
                          </span>
                      </div>
                      
                    </Col>

                    <Col className="mb-3 col-lg-6 col-6">
                      <div className='mb-3 bg-light py-2 px-3 rounded'>
                        <label className='fw-bold'>{t('inputFields.xpScToGcRate.label')}</label>
                          <span className='mb-0'>
                              <InputGroup>
                              <BForm.Control
                                  type={'number'}
                                  autoComplete="off"
                                  name={'xpScToGcRate'}
                                  disabled={!editable}
                                  value={values?.xpScToGcRate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                              </InputGroup>

                              <ErrorMessage
                              component='div'
                              name={'xpScToGcRate'}
                              className='text-danger'
                              />
                          </span>
                      </div>
                      
                    </Col>
                    
                    <Col className="mb-3 col-lg-6 col-6">
                      
                      <div className='mb-3 bg-light py-2 px-3 rounded'>
                        <label className='fw-bold'>{t('inputFields.scToGcRate.label')}</label>
                          <span className='mb-0'>
                              <InputGroup>
                              <BForm.Control
                                  type={'number'}
                                  autoComplete="off"
                                  name={'scToGcRate'}
                                  disabled={!editable}
                                  value={values?.scToGcRate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                              </InputGroup>

                              <ErrorMessage
                              component='div'
                              name={'scToGcRate'}
                              className='text-danger'
                              />
                          </span>
                      </div>
                      
                    </Col>

                    <Col className="mb-3 col-lg-6 col-6">
                      
                      <div className='mb-3 bg-light py-2 px-3 rounded'>
                        <label className='fw-bold'>{t('inputFields.minScSpinLimit.label')}</label>
                          <span className='mb-0'>
                              <InputGroup>
                              <BForm.Control
                                  type='number'
                                  autoComplete="off"
                                  min={0}
                                  name='minScSpinLimit'
                                  disabled={!editable}
                                  value={values?.minScSpinLimit}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                 
                              </InputGroup>

                              <ErrorMessage
                              component='div'
                              name={'minScSpinLimit'}
                              className='text-danger'
                              />
                          </span>
                      </div>
                      
                    </Col>
            
                    <Col className="mb-3 col-lg-6 col-6">
                      
                      <div className='mb-3 bg-light py-2 px-3 rounded'>
                        <label className='fw-bold'>{t('inputFields.minGcSpinLimit.label')}</label>
                          <span className='mb-0'>
                              <InputGroup>
                              <BForm.Control
                                  type='number'
                                  autoComplete="off"
                                  min={0}
                                  name='minGcSpinLimit'
                                  disabled={!editable}
                                  value={values?.minGcSpinLimit}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                              </InputGroup>

                              <ErrorMessage
                              component='div'
                              name={'minGcSpinLimit'}
                              className='text-danger'
                              />
                          </span>
                      </div>
                      
                    </Col>
                   
                  </Row>

                  <Row>
                  <Col className="mb-3 col-lg-6 col-6">
                      
                      <div className='mb-3 bg-light py-2 px-3 rounded'>
                        <label className='fw-bold'>{t('inputFields.gcVaultPercentage.label')}</label>
                          <span className='mb-0'>
                              <InputGroup>
                              <BForm.Control
                                  type='number'
                                  autoComplete="off"
                                  min={0}
                                  name='gcVaultPercentage'
                                  disabled={!editable}
                                  value={values?.gcVaultPercentage}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                              </InputGroup>

                              <ErrorMessage
                              component='div'
                              name={'gcVaultPercentage'}
                              className='text-danger'
                              />
                          </span>
                      </div>
                      
                    </Col>

                    <Col className="mb-3 col-lg-6 col-6">
                      
                      <div className='mb-3 bg-light py-2 px-3 rounded'>
                        <label className='fw-bold'>{t('inputFields.scVaultPercentage.label')}</label>
                          <span className='mb-0'>
                              <InputGroup>
                              <BForm.Control
                                  type='number'
                                  autoComplete="off"
                                  min={0}
                                  name='scVaultPercentage'
                                  disabled={!editable}
                                  value={values?.scVaultPercentage}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                              />
                              </InputGroup>

                              <ErrorMessage
                              component='div'
                              name={'scVaultPercentage'}
                              className='text-danger'
                              />
                          </span>
                      </div>
                      
                    </Col>
                   
                  </Row>
                  <div className='mt-0 mb-3'>
                    <Button
                      variant='success'
                      onClick={handleSubmit}
                      className='ml-2'
                      hidden={!editable}
                      disabled={loading}
                    >
                      {t('submitButton')}
                      {loading && (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                          style={{marginLeft: '3px'}}
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>}

      </Row>
    </>
  )
}

export default SiteConfiguration
